import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState, useRef } from 'react';
import isMobilePhone from 'validator/lib/isMobilePhone';
import moment from 'moment';
import { auth, functions, db, storage } from '../utils/firebase';
import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuthState } from 'react-firebase-hooks/auth';
import OrderVisual from '../components/OrderVisual';
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  emailRegExp,
  letSpcRegEx,
  letNumSpcRegEx,
  minDate,
  addDays,
  dateLocalToTz,
  dateTzToLocal,
} from '../utils/miscellaneous';
import { adminState } from '../utils/states';
import { whiteLabelData, isMuvaFreight } from '../utils/whitelabeling';
import { states } from '../utils/googleMaps';
import { zips } from '../utils/zips';
import { apiKey } from '../utils/googleMaps';

const orderSave = httpsCallable(functions, 'order-save');
const orderAddVideo = httpsCallable(functions, 'order-addVideo');
const orderCreate = httpsCallable(functions, 'order-create');
const orderSubmitRequest = httpsCallable(functions, 'order-submitRequest');
const orderCancel = httpsCallable(functions, 'order-cancel');

const initialValues = {
  moveType: isMuvaFreight() ? 'Freight' : '',
  companyName: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  pickUpDate: addDays(new Date(), 21),
  pickUpTime: '8AM - 9AM',
  storageRequired: isMuvaFreight() ? false : undefined,
  noPacking: undefined,
  requirements: '',
  zipFrom: '',
  countryFrom: 'US',
  addressFrom: '',
  suiteFrom: '',
  cityFrom: '',
  stateFrom: 'Alabama',
  bedroomsFrom: isMuvaFreight() ? '0' : '',
  parkingFrom: '',
  zipTo: '',
  countryTo: 'US',
  addressTo: '',
  suiteTo: '',
  cityTo: '',
  stateTo: 'Alabama',
  bedroomsTo: isMuvaFreight() ? '0' : '',
  parkingTo: '',
  files: [],
  videos: [],
};

export default function Order() {
  const [orderId, setOrderId] = useState(null);
  const [state, setState] = useState(adminState.init);
  const [assigns, setAssigns] = useState({});
  const [awaitFlag, setAwaitFlag] = useState(false);
  const [videosUrls, setVideosUrls] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const unsub = useRef({ quotes: null });
  const [currentQuote, setCurrentQuote] = useState(null);
  const [newQuoteId, setNewQuoteId] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState(null);
  const [user] = useAuthState(auth);
  const adrValidErrFrom = useRef({
    address: null,
    city: null,
    state: null,
    zip: null,
  });
  const adrValidErrTo = useRef({
    address: null,
    city: null,
    state: null,
    zip: null,
  });

  const validationSchema = new Yup.ObjectSchema({
    zipFrom: Yup.string()
      .test(
        'zip-from-exists',
        'wrong code',
        (value, context) =>
          zips[context.parent.countryFrom][value] !== undefined
      )
      .required('Required')
      .test('zip-validation-error', 'unconfirmed', (value, context) => {
        const zip = adrValidErrFrom.current.zip;
        if (zip) return context.createError({ message: zip });
        return true;
      }),
    zipTo: Yup.string()
      .test(
        'zip-to-exists',
        'wrong code',
        (value, context) => zips[context.parent.countryTo][value] !== undefined
      )
      .required('Required')
      .test('zip-validation-error', 'unconfirmed', (value, context) => {
        const zip = adrValidErrTo.current.zip;
        if (zip) return context.createError({ message: zip });
        return true;
      }),
    companyName: Yup.string().test('required', 'Required', (value, context) => {
      if (context.parent.moveType === 'Freight' && !value) return false;
      return true;
    }),
    firstName: Yup.string()
      .max(50, '50 characters allowed only')
      .matches(letSpcRegEx, 'Invalid name')
      .required('Required'),
    lastName: Yup.string()
      .max(50, '50 characters allowed only')
      .matches(letSpcRegEx, 'Invalid name')
      .required('Required'),
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
    phone: Yup.string()
      .test('phone-validation', 'Invalid phone', (value) =>
        isMobilePhone(value, ['en-US', 'en-AU'])
      )
      .required('Required'),
    pickUpDate: Yup.date(),
    storageRequired: Yup.boolean(),
    addressFrom: Yup.string()
      .required('Required')
      .test('address-validation-error', 'unconfirmed', (value, context) => {
        const address = adrValidErrFrom.current.address;
        if (address) return context.createError({ message: address });
        return true;
      }),
    cityFrom: Yup.string()
      .required('Required')
      .test('city-validation-error', 'unconfirmed', (value, context) => {
        const city = adrValidErrFrom.current.city;
        if (city) return context.createError({ message: city });
        return true;
      }),
    stateFrom: Yup.string()
      .test('zip-according', "doesn't match zip", (value, context) => {
        const country = context.parent.countryFrom;
        const zip = context.parent.zipFrom;
        const zipObj = zips[country][zip];

        if (!zipObj) return true;

        return (
          value ===
          states[country].find((item) => item.abbreviation === zipObj.state)
            .name
        );
      })
      .test('state-validation-error', 'unconfirmed', (value, context) => {
        const state = adrValidErrFrom.current.state;
        if (state) return context.createError({ message: state });
        return true;
      }),
    bedroomsFrom: Yup.number()
      .typeError('Not a number')
      .integer('Integers only')
      .min(0, 'Negative number')
      .max(25, '25 is maximum')
      .required('Required'),
    parkingFrom: Yup.string()
      .max(50, '50 characters allowed only')
      .matches(letNumSpcRegEx, 'Unacceptable characters')
      .required('Required'),
    addressTo: Yup.string()
      .required('Required')
      .test('same-as-from', 'same as pick up address', (value, context) => {
        if (
          value === context.parent.addressFrom &&
          context.parent.suiteTo === context.parent.suiteFrom &&
          context.parent.cityTo === context.parent.cityFrom &&
          context.parent.stateTo === context.parent.stateFrom &&
          context.parent.countryTo === context.parent.countryFrom
        )
          return false;
        return true;
      })
      .test('address-validation-error', 'unconfirmed', (value, context) => {
        const address = adrValidErrTo.current.address;
        if (address) return context.createError({ message: address });
        return true;
      }),
    cityTo: Yup.string()
      .required('Required')
      .test('city-validation-error', 'unconfirmed', (value, context) => {
        const city = adrValidErrTo.current.city;
        if (city) return context.createError({ message: city });
        return true;
      }),
    stateTo: Yup.string()
      .test('zip-according', "doesn't match zip", (value, context) => {
        const country = context.parent.countryTo;
        const zip = context.parent.zipTo;
        const zipObj = zips[country][zip];

        if (!zipObj) return true;

        return (
          value ===
          states[country].find((item) => item.abbreviation === zipObj.state)
            .name
        );
      })
      .test('state-validation-error', 'unconfirmed', (value, context) => {
        const state = adrValidErrTo.current.state;
        if (state) return context.createError({ message: state });
        return true;
      }),
    bedroomsTo: Yup.number()
      .typeError('Not a number')
      .integer('Integers only')
      .min(0, 'Negative number')
      .max(25, '25 is maximum')
      .required('Required'),
    parkingTo: Yup.string()
      .max(50, '50 characters allowed only')
      .matches(letNumSpcRegEx, 'Unacceptable characters')
      .required('Required'),
    files: Yup.array()
      .min(1, 'Choose or record at least one media file')
      .max(10, 'No more than 10 files are allowed')
      .test('files-size', 'Not more than 1Gb is allowed', (value) => {
        let size = 0;
        value.forEach((file) => (size += file.size));
        if (size > 1000000000) return false;
        return true;
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  // Address validation function
  const addressValidate = async (addressLine, errRef) => {
    try {
      const resAdr = (
        await axios.post(
          'https://addressvalidation.googleapis.com/v1:validateAddress?key=' +
            apiKey,
          {
            address: {
              addressLines: [addressLine],
            },
          }
        )
      )?.data?.result?.address;

      if (!resAdr) return;

      let address = null,
        city = null,
        state = null,
        zip = null;

      // Checking addressComponents for validation errors
      resAdr.addressComponents.forEach((component) => {
        const errMsg = 'unconfirmed';
        if (
          component.componentType !== 'subpremise' &&
          (component.confirmationLevel !== 'CONFIRMED' ||
            component.replaced ||
            component.spellCorrected)
        )
          switch (component.componentType) {
            case 'postal_code':
              zip = errMsg;
              break;
            case 'locality':
              city = errMsg;
              break;
            case 'administrative_area_level_1':
              state = errMsg;
              break;
            case 'country':
              break;
            default:
              address = errMsg;
              break;
          }
      });

      // Checking missingComponentTypes but ignoring supremise issues
      if (
        resAdr.missingComponentTypes &&
        !(
          resAdr.missingComponentTypes.length === 1 &&
          resAdr.missingComponentTypes[0] === 'subpremise'
        )
      )
        address = 'incomplete';

      errRef.current = {
        address,
        city,
        state,
        zip,
      };
    } catch (error) {
      console.error(error);
    }
  };

  // Reset pick up address validation errors
  useEffect(() => {
    adrValidErrFrom.current = {
      address: null,
      city: null,
      state: null,
      zip: null,
    };
  }, [
    formik.values.zipFrom,
    formik.values.cityFrom,
    formik.values.stateFrom,
    formik.values.countryFrom,
    formik.values.addressFrom,
  ]);

  // Reset destination address validation errors
  useEffect(() => {
    adrValidErrTo.current = {
      address: null,
      city: null,
      state: null,
      zip: null,
    };
  }, [
    formik.values.zipTo,
    formik.values.cityTo,
    formik.values.stateTo,
    formik.values.countryTo,
    formik.values.addressTo,
  ]);

  useEffect(() => {
    const getFilesUrls = async () => {
      const getUrlPromises = [];
      const getMetaPromises = [];

      formik.values.videos.forEach((video) => {
        const fileRef = ref(storage, `videos/${orderId}/${video}`);
        getUrlPromises.push(getDownloadURL(fileRef));
        getMetaPromises.push(getMetadata(fileRef));
      });

      const res = await Promise.all([
        Promise.all(getUrlPromises),
        Promise.all(getMetaPromises),
      ]);

      setVideosUrls(
        res[0].map((url, i) => ({
          url,
          type: res[1][i].contentType.split('/')[0],
        }))
      );
    };

    getFilesUrls();
  }, [formik.values.videos]);

  //Subscribing on user's quotes
  useEffect(() => {
    const onError = (err) =>
      console.error(`Quotes loading failure: ${err.message}`);

    const onQuotesLoad = (quotesSnap) => {
      quotesSnap.docChanges().forEach((quoteChange) => {
        const newQuote = { id: quoteChange.doc.id, ...quoteChange.doc.data() };

        //Transforming data from Firebase format and timezone to local
        if (newQuote.details.moveDate)
          newQuote.details.moveDate = dateTzToLocal(
            newQuote.details.moveDate.toDate()
          );
        if (newQuote.details.moveInDate)
          newQuote.details.moveInDate = dateTzToLocal(
            newQuote.details.moveInDate.toDate()
          );

        switch (quoteChange.type) {
          case 'removed':
            setQuotes((currentQuotes) =>
              currentQuotes.filter((quote) => quote.id !== newQuote.id)
            );
            break;
          case 'added':
            setQuotes((currentQuotes) => {
              const index = currentQuotes.findIndex(
                (quote) => quote.id === newQuote.id
              );
              if (index === -1) return [...currentQuotes, newQuote];
              return currentQuotes;
            });
            break;
          case 'modified':
            setQuotes((currentQuotes) => {
              const index = currentQuotes.findIndex(
                (quote) => quote.id === newQuote.id
              );
              if (index > -1)
                return [
                  ...currentQuotes.slice(0, index),
                  newQuote,
                  ...currentQuotes.slice(index + 1, currentQuotes.length),
                ];
              return currentQuotes;
            });
            break;
          default:
            break;
        }
      });
    };

    const openQuotesList = async () => {
      const { uid } = user;
      const quotesQuery = query(
        collection(db, 'quotes'),
        where('userID', '==', uid),
        where('appID', '==', whiteLabelData().title)
      );
      setAwaitFlag(true);
      await getDocs(quotesQuery); //It doesn't work without it for unknown reasons
      setAwaitFlag(false);
      unsub.current.quotes = onSnapshot(quotesQuery, onQuotesLoad, onError);
    };

    const closeQuotesList = () => {
      if (unsub.current.quotes) unsub.current.quotes();
    };

    if (user) openQuotesList();
    return closeQuotesList();
  }, [user]);

  //Seting formik and some other variables to process a quote
  useEffect(() => {
    //Making sure moveDate is not less than minimal acceptable date for draft quotes
    const getPickUpDate = () => {
      if (
        quotes[currentQuote].state === adminState.init &&
        quotes[currentQuote].details.moveDate &&
        quotes[currentQuote].details.moveDate < minDate()
      )
        return minDate();
      return quotes[currentQuote].details.moveDate;
    };

    const setFormik = () => {
      setOrderId(quotes[currentQuote].id);
      setState(quotes[currentQuote].state);
      setAssigns(quotes[currentQuote].assigns);
      formik.setErrors({});
      formik.setTouched({});
      formik.setValues({
        ...formik.values,
        moveType: quotes[currentQuote].moveType,
        companyName: quotes[currentQuote].contact.companyName,
        firstName: quotes[currentQuote].contact.name.split(' ')[0],
        lastName: quotes[currentQuote].contact.name
          .split(' ')
          .slice(1)
          .join(' '),
        email: quotes[currentQuote].contact.email,
        phone: quotes[currentQuote].contact.phone,
        pickUpDate: getPickUpDate(),
        pickUpTime: quotes[currentQuote].details.moveWindow,
        storageRequired: quotes[currentQuote].details.storageRequired,
        noPacking: quotes[currentQuote].details.noPacking,
        requirements: quotes[currentQuote].details.requirements,
        zipFrom: quotes[currentQuote].pickup.zip,
        countryFrom: quotes[currentQuote].pickup.country,
        addressFrom: quotes[currentQuote].pickup.address,
        suiteFrom: quotes[currentQuote].pickup.suite,
        cityFrom: quotes[currentQuote].pickup.city,
        stateFrom: quotes[currentQuote].pickup.state,
        bedroomsFrom: quotes[currentQuote].pickup.bedroomCount,
        parkingFrom: quotes[currentQuote].pickup.parking,
        zipTo: quotes[currentQuote].destination.zip,
        countryTo: quotes[currentQuote].destination.country,
        addressTo: quotes[currentQuote].destination.address,
        suiteTo: quotes[currentQuote].destination.suite,
        cityTo: quotes[currentQuote].destination.city,
        stateTo: quotes[currentQuote].destination.state,
        bedroomsTo: quotes[currentQuote].destination.bedroomCount,
        parkingTo: quotes[currentQuote].destination.parking,
        videos: quotes[currentQuote].videos ?? [],
      });
    };

    // Need to check moveDate to be sure that a quote is initiated
    if (currentQuote !== null && quotes[currentQuote].details.moveDate)
      setFormik();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuote, quotes]);

  const submitOrder = async () => {
    await orderSubmitRequest({ orderID: orderId });
  };

  const uploadMediaFiles = async () => {
    const filesLen = formik.values.files.length;

    if (filesLen === 0) return null;

    const totalBytes = new Array(filesLen).fill(0);
    const bytesTransferred = new Array(filesLen).fill(0);

    const uploadRes = await Promise.allSettled(
      formik.values.files.map((file, i) => {
        let fileExt;
        if (file instanceof File) {
          fileExt = file.name.split('.').pop();
        } else fileExt = file.type.split(';')[0].split('/')[1];
        const fileName = uuidv4() + '.' + fileExt;
        const storageRef = ref(storage, `videos/${orderId}/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', (snapshot) => {
          totalBytes[i] = snapshot.totalBytes;
          bytesTransferred[i] = snapshot.bytesTransferred;

          const totalSum = totalBytes.reduce(
            (accum, current) => accum + current
          );
          const transSum = bytesTransferred.reduce(
            (accum, current) => accum + current
          );

          setUploadingProgress(Math.floor((100 * transSum) / totalSum));
        });

        return uploadTask;
      })
    );

    setUploadingProgress(null);
    formik.setFieldValue('files', []);

    return uploadRes
      .filter((item) => item.status === 'fulfilled')
      .map((item) => item.value.metadata.name);
  };

  const videoSave = async () => {
    const videos = await uploadMediaFiles();
    if (videos) {
      formik.setFieldValue('videos', videos);
      orderSave({ orderID: orderId, videos });
    }
  };

  const videoAdd = async () => {
    const videos = await uploadMediaFiles();
    if (videos) {
      formik.setFieldValue('videos', [...formik.values.videos, ...videos]);
      orderAddVideo({
        orderID: orderId,
        videos: videos.map((video) => ({ uri: video })),
      });
    }
  };

  const save = async (id) => {
    const {
      moveType,
      email,
      companyName,
      firstName,
      lastName,
      phone,
      pickUpDate,
      pickUpTime,
      noPacking,
      requirements,
      storageRequired,
      addressFrom,
      suiteFrom,
      bedroomsFrom,
      countryFrom,
      cityFrom,
      parkingFrom,
      stateFrom,
      zipFrom,
      addressTo,
      suiteTo,
      bedroomsTo,
      countryTo,
      cityTo,
      zipTo,
      parkingTo,
      stateTo,
    } = formik.values;

    const saveObj = {
      orderID: id ?? orderId,
      appID: whiteLabelData().title,
      moveType,
      contact: {
        companyName,
        email,
        name: firstName + ' ' + lastName,
        phone,
      },
      details: {
        moveDate: dateLocalToTz(pickUpDate),
        moveWindow: pickUpTime,
      },
      destination: {
        address: addressTo,
        suite: suiteTo,
        bedroomCount: bedroomsTo,
        city: cityTo,
        parking: parkingTo,
        state: stateTo,
        country: countryTo,
        zip: zipTo,
      },
      pickup: {
        address: addressFrom,
        suite: suiteFrom,
        bedroomCount: bedroomsFrom,
        city: cityFrom,
        parking: parkingFrom,
        state: stateFrom,
        country: countryFrom,
        zip: zipFrom,
      },
    };

    if (noPacking !== undefined) saveObj.details.noPacking = noPacking;
    if (storageRequired !== undefined) {
      saveObj.details.storageRequired = storageRequired;
      saveObj.details.moveInDate = moment(saveObj.details.moveDate)
        .add(2, 'days')
        .toDate();
    }
    if (requirements) saveObj.details.requirements = requirements;

    orderSave(saveObj);
  };

  // Set the current quote after new quote creation and saving
  // This work together with orderCreateWrapper
  useEffect(() => {
    if (newQuoteId !== null) {
      const index = quotes.findIndex((quote) => quote.id === newQuoteId);
      if (index > -1) {
        setCurrentQuote(index);
        setNewQuoteId(null);
        setAwaitFlag(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotes]);

  // Save the quote with initialValues after creation
  // This work together with orderCreateWrapper
  useEffect(() => {
    if (newQuoteId !== null) save(newQuoteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newQuoteId]);

  //Creating of a new order
  const orderCreateWrapper = async () => {
    setAwaitFlag(true);
    formik.resetForm();

    const toCopy = quotes
      .sort((a, b) => (a.details.moveDate < b.details.moveDate ? 1 : -1))
      .find(
        (quote) =>
          quote.contact.email &&
          quote.contact.phone &&
          quote.contact.name &&
          quote.contact.name !== ' '
      );

    if (toCopy) {
      formik.setFieldValue('companyName', toCopy.contact.companyName);
      formik.setFieldValue('firstName', toCopy.contact.name.split(' ')[0]);
      formik.setFieldValue(
        'lastName',
        toCopy.contact.name.split(' ').slice(1).join(' ')
      );
      formik.setFieldValue('email', toCopy.contact.email);

      // Digits only is alowed for a phone
      const digits = toCopy.contact.phone.match(/\d+/g);
      const phone = digits ? digits.join('') : '';
      formik.setFieldValue('phone', phone);
    }

    const res = await orderCreate();
    setNewQuoteId(res.data.orderID);
  };

  const orderCancelWrapper = async ({ code, reason }) => {
    setAwaitFlag(true);
    await orderCancel({ orderID: orderId, code, reason });
    setAwaitFlag(false);
  };

  useEffect(() => {
    formik.setFieldTouched('zipFrom');
    formik.validateField('zipFrom');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.countryFrom]);

  useEffect(() => {
    formik.setFieldTouched('zipTo');
    formik.validateField('zipTo');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.countryTo]);

  //We don't use bedrooms for Freight so setting it to 0
  useEffect(() => {
    if (formik.values.moveType === 'Freight') {
      formik.setFieldValue('bedroomsFrom', '0');
      formik.setFieldValue('bedroomsTo', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.moveType]);

  // Storage required is false by default for freight
  useEffect(() => {
    if (formik.values.moveType === 'Freight') {
      formik.setFieldValue('storageRequired', false);
    } else formik.setFieldValue('storageRequired', undefined);
  }, [formik.values.moveType]);

  useEffect(() => {
    const country = formik.values.countryFrom;
    const zip = formik.values.zipFrom;
    const zipObj = zips[country][zip];

    if (zipObj)
      formik.setValues({
        ...formik.values,
        stateFrom: states[country].find(
          (item) => item.abbreviation === zipObj.state
        ).name,
        cityFrom: zipObj.city,
      });
  }, [formik.values.zipFrom]);

  useEffect(() => {
    const country = formik.values.countryTo;
    const zip = formik.values.zipTo;
    const zipObj = zips[country][zip];

    if (zipObj)
      formik.setValues({
        ...formik.values,
        stateTo: states[country].find(
          (item) => item.abbreviation === zipObj.state
        ).name,
        cityTo: zipObj.city,
      });
  }, [formik.values.zipTo]);

  return (
    <OrderVisual
      formik={formik}
      save={save}
      awaitFlag={awaitFlag}
      videoAdd={videoAdd}
      videoSave={videoSave}
      uploadingProgress={uploadingProgress}
      orderId={orderId}
      state={state}
      assigns={assigns}
      submitOrder={submitOrder}
      videosUrls={videosUrls}
      orderCancel={orderCancelWrapper}
      orderCreate={orderCreateWrapper}
      quotes={quotes}
      currentQuote={currentQuote}
      setCurrentQuote={setCurrentQuote}
      addressValidateFrom={(addressLine) =>
        addressValidate(addressLine, adrValidErrFrom)
      }
      addressValidateTo={(addressLine) =>
        addressValidate(addressLine, adrValidErrTo)
      }
    />
  );
}
