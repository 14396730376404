import MuvaLogo from '../assets/images/logo-black.png';
import ShipSmartBg from '../assets/images/ship-image-bg.png';
import ShipSmartLogo from '../assets/images/logo-shipsmart.png';
import GentleGiantBg from '../assets/images/giant-image-bg.png';
import GentleGiantBgHome from '../assets/images/giant-image-bg2.png';
import GentleGiantLogo from '../assets/images/gentlegiant-logo.png';
import MuvaFreightLogo from '../assets/images/muva-freight-logo-white.png';
import MuvaFreightBg from '../assets/images/muva-freight-bg.png';
import AmericanDreamLogo from '../assets/images/americandream-logo.png';
import AmericanDreamBg from '../assets/images/americandream-bg.jpg';
import Home from '../components/Home';
import IntroTextDemo from '../components/IntroTextDemo';
import IntroTextAmericanDream from '../components/IntroTextAmericanDream';
import ShipSmartMilitary from '../components/ShipSmartMilitary';
import IntroTextMuvaFreight from '../components/IntroTextMuvaFreight';
import IntroTextHauler from '../components/IntroTextHauler';
import OutroTextFreightHauler from '../components/OutroTextFreightHauler';

const movers = {
  default: 'default',
  gentleGiant: 'Gentle Giant',
  shipSmart: 'Ship Smart',
  shipSmartMilitary: 'Ship Smart Military',
  shipSmartMarine: 'Ship Smart Marine',
  muvaFreight: 'Muva Freight',
  haulerNetwork: 'Hauler Network',
  americanDream: 'American Dream',
  demo: 'Demo',
};

const hosts = {
  localhost: movers.demo,
  'muva-webapp.firebaseapp.com': movers.gentleGiant,
  'muva-webapp.web.app': movers.shipSmart,
  'ggiant.themuva.com': movers.gentleGiant,
  'shipsmart.themuva.com': movers.shipSmart,
  'shipmilitary.themuva.com': movers.shipSmartMilitary,
  'shipsmartmilitary.themuva.com': movers.shipSmartMilitary,
  'smartmilitary.themuva.com': movers.shipSmartMarine,
  'ships.themuva.com': movers.shipSmartMarine,
  'smart.themuva.com': movers.shipSmartMarine,
  'freight.themuva.com': movers.muvaFreight,
  'hauler.themuva.com': movers.haulerNetwork,
  'americandream.themuva.com': movers.americanDream,
  'muvafreight.net': movers.muvaFreight,
  'hauler.muvafreight.net': movers.haulerNetwork,
  'demo.themuva.com': movers.demo,
};

const moverData = [];

moverData[movers.default] = {
  mover: movers.default,
  background: `url(${ShipSmartBg})`,
  backgroundHome: `url(${ShipSmartBg})`,
  backgroundPosition: 'left 30% bottom 20%',
  logo: `url(${MuvaLogo})`,
  title: 'Muva',
  favIconUrl: '/favicon.png',
};

moverData[movers.muvaFreight] = {
  mover: movers.muvaFreight,
  background: `url(${MuvaFreightBg})`,
  backgroundHome: `url(${MuvaFreightBg})`,
  backgroundPosition: 'left 28% bottom 35%',
  logo: `url(${MuvaFreightLogo})`,
  title: 'Muva Freight',
  favIconUrl: '/favicon.png',
  home: (
    <Home
      intro={IntroTextMuvaFreight}
      outro={() => (
        <OutroTextFreightHauler
          to='https://hauler.muvafreight.net'
          text={`Are you a hauler?\nClick here to join the\u00A0Hauler\u00A0Network\nto pickup more\u00A0loads`}
        />
      )}
    />
  ),
};

moverData[movers.haulerNetwork] = {
  mover: movers.haulerNetwork,
  background: `url(${MuvaFreightBg})`,
  backgroundHome: `url(${MuvaFreightBg})`,
  backgroundPosition: 'left 28% bottom 35%',
  logo: `url(${MuvaFreightLogo})`,
  title: 'Muva Freight',
  favIconUrl: '/favicon.png',
  home: (
    <Home
      intro={IntroTextHauler}
      outro={() => (
        <OutroTextFreightHauler
          to='https://muvafreight.net'
          text={`Need to ship freight?\nClick here to use Muva\u00A0Freight\u00A0service`}
        />
      )}
      navigatePath='/market'
    />
  ),
};

moverData[movers.shipSmart] = {
  mover: movers.shipSmart,
  background: `url(${ShipSmartBg})`,
  backgroundHome: `url(${ShipSmartBg})`,
  backgroundPosition: 'left 30% bottom 20%',
  logo: `url(${ShipSmartLogo})`,
  title: 'Ship Smart',
  favIconUrl: '',
};

moverData[movers.shipSmartMilitary] = {
  mover: movers.shipSmartMilitary,
  background: `url(${ShipSmartBg})`,
  backgroundHome: `url(${ShipSmartBg})`,
  backgroundPosition: 'left 30% bottom 20%',
  logo: `url(${ShipSmartLogo})`,
  title: 'Ship Smart',
  favIconUrl: '',
  home: <Home intro={() => <ShipSmartMilitary image='army' />} />,
};

moverData[movers.shipSmartMarine] = {
  mover: movers.shipSmartMarine,
  background: `url(${ShipSmartBg})`,
  backgroundHome: `url(${ShipSmartBg})`,
  backgroundPosition: 'left 30% bottom 20%',
  logo: `url(${ShipSmartLogo})`,
  title: 'Ship Smart',
  favIconUrl: '',
  home: <Home intro={() => <ShipSmartMilitary image='marine' />} />,
};

moverData[movers.gentleGiant] = {
  mover: movers.gentleGiant,
  background: `url(${GentleGiantBg})`,
  backgroundHome: `url(${GentleGiantBgHome})`,
  backgroundPosition: 'left 20% top 0%',
  logo: `url(${GentleGiantLogo})`,
  title: 'Gentle Giant',
  favIconUrl: '',
};

moverData[movers.americanDream] = {
  mover: movers.americanDream,
  background: `url(${AmericanDreamBg})`,
  backgroundHome: `url(${AmericanDreamBg})`,
  backgroundPosition: 'left 50% top 100%',
  logo: `url(${AmericanDreamLogo})`,
  title: 'American Dream',
  favIconUrl: '',
  home: <Home intro={IntroTextAmericanDream} />,
};

moverData[movers.demo] = {
  mover: movers.demo,
  background: `url(${ShipSmartBg})`,
  backgroundHome: `url(${ShipSmartBg})`,
  backgroundPosition: 'left 30% bottom 20%',
  logo: `url(${MuvaLogo})`,
  title: 'Demo',
  favIconUrl: '',
  email: 'info@themuva.com',
  home: <Home intro={IntroTextDemo} />,
};

export const whiteLabelData = () =>
  moverData[hosts[window.location.hostname]] ?? moverData[movers.default];

export const isMuvaFreight = () =>
  hosts[window.location.hostname] === movers.muvaFreight;

export const isHaulerNetwork = () =>
  hosts[window.location.hostname] === movers.haulerNetwork;

export const isMuvaDemo = () => hosts[window.location.hostname] === movers.demo;
